<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        
        <div class="d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid src="@/assets/images/fondo.jpg" class="image-responsive w-full" alt="fondo" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="w-100 d-lg-flex h-20 d-flex justify-content-center">
            <b-img class="" fluid :src="logoImg" alt="LOGO" />
          </div>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 mt-4">
            Bienvenid@ a Catalunya Padel Club! 👋
          </b-card-title>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="success"
                block
                @click="validationForm"
              >
                Entrar
              </b-button>
              <!-- <b-button
                type="button"
                variant="primary"
                block
                link
                to="/register"
                
              >
              Registrarse
              </b-button> -->
              <!-- <b-button
                type="button"
                variant="warning"
                block
                link
                to="/recover"
                
              >
              Recuperar Contraseña
              </b-button> -->
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
      <!-- /Login-->
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, } from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, ValidationProvider, ValidationObserver, },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: true,
      password: "",
      userEmail: "",
      logoImg : require("@/assets/images/logo/logo.jpg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    async login(){
      await this.$store.dispatch("auth/login",{email: this.userEmail,password: this.password,remember_me: this.status})
      if (this.$store.state.auth.is_auth) {
        this.$router.push('/');
      }else{
        alert('¡Usuario o contraseña incorrectos!')
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },
    me() {
      this.$http.get('/api/auth/user').then( response => {
        window.console.log(data);
      })
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
